@font-face {
  font-family: "Arima Madurai";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Arima Madurai Thin"), local("ArimaMadurai-Thin"),
    url(https://fonts.gstatic.com/s/arimamadurai/v6/t5t4IRoeKYORG0WNMgnC3seB1V3_rbuD.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Arima Madurai";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Arima Madurai Medium"), local("ArimaMadurai-Medium"),
    url(https://fonts.gstatic.com/s/arimamadurai/v6/t5t7IRoeKYORG0WNMgnC3seB1c3supymfw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Assistant Light"), local("Assistant-Light"),
    url(https://fonts.gstatic.com/s/assistant/v5/2sDZZGJYnIjSi6H75xk7w0esBJ0Y.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
