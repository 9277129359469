.menu {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  @include respond(grande) {
    flex-flow: column nowrap;
  }
  @include respond(short-with-sugar) {
    padding: 1rem;
  }

  &--container {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    border-right: 1px solid $white;

    @include respond(grande) {
      border-right: none;

      &:first-child {
        border-left: none;
      }
    }

    &:first-child {
      border-left: 1px solid $white;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 11rem;

    @include respond(trenta) {
      font-size: 9rem;
    }

    @include respond(short) {
      font-size: 6rem;
    }

    @supports (-webkit-text-stroke: 1px $white) {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;
      -webkit-text-fill-color: transparent;
    }

    &--about {
      flex-grow: 1;
      padding-left: 2rem;
      justify-content: flex-start;
      border-bottom: 1px solid $white;

      @include respond(grande) {
        border-bottom: none;
      }

      @include respond(short) {
        justify-content: center;
        padding-left: initial;
      }
    }

    &--home {
      justify-content: center;
      border-top: 1px solid;

      @include respond(grande) {
        justify-content: flex-end;
        border-top: none;
      }

      @include respond(short) {
        justify-content: center;
      }
    }

    &--contact {
      justify-content: flex-end;
      padding-right: 2rem;
      opacity: 0.75;
      font-size: 2rem;
      border-top: 1px solid $white;
      text-align: right;
      @include respond(short) {
        text-align: left;
      }
    }
  }

  &--subtitle {
    padding-left: 2rem;
    font-size: 2.5rem;
  }

  &__list {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    list-style: none;
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;

    @include respond(grande) {
      border-top: none;
    }

    &__researches {
      position: relative;
      @include respond(grande) {
        border-right: 1px solid $white;
      }
    }

    &--image-container {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 10000;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      background: $black;
    }

    &--item {
      padding-left: 4rem;
      margin-bottom: 0.5rem;
      color: $white;
      opacity: 0.25;
      font-family: $font;
      font-size: 1.75rem;
      cursor: pointer;

      &:last-child {
        margin-bottom: 1.5rem;
      }
    }
  }
}
