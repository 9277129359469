.sidebar {
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 0;
  width: 35%;
  min-width: 20rem;
  height: 100vh;
  min-height: 30rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.3rem;
  background: $black;
  box-shadow: -1px 0px 5px $black;
  overflow-y: auto;
  overflow-x: hidden;

  @include respond(venti-with-sugar) {
    padding: 1.5rem;
  }

  @include respond(venti) {
    width: 55%;
  }

  @include respond(short-with-sugar) {
    top: initial;
    bottom: 0;
    width: 100vw;
    max-height: 60vh;
    height: initial;
    padding: 0.5rem 1rem;
    border-top: 1px solid $white;
    box-shadow: 0px -2px 5px $black;
  }

  & h1 {
    text-transform: uppercase;
    font-size: 2.25rem;
    font-weight: 100;
  }

  & h2 {
    text-transform: uppercase;
  }
  &--image {
    width: 23rem;
    justify-self: center;
    align-self: center;

    & > img {
      height: initial;
      width: 100%;
    }
    @include respond(depth-charge) {
      max-height: 40rem;
    }
  }
  &--subtitle {
    font-weight: bold;
  }
  &--text {
    font-family: $font;
    text-align: justify;
  }

  &--button {
    margin: 2rem auto;
  }
}
