.not-found {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > svg {
    // transform: scale(0.25);
    width: 30rem;
    height: 30rem;
    @include respond(short) {
      width: 25rem;
      height: 25rem;
    }
  }
  & > h1 {
    width: 100%;
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-size: 4rem;
    font-weight: 100;
    text-align: center;
    @include respond(short-with-sugar) {
      font-size: 3rem;
    }
    @include respond(short) {
      font-size: 2.5rem;
    }
  }
  &--button {
    margin: 2rem;
    font-size: 1.2rem;
    @include respond(short-with-sugar) {
      font-size: 1.5rem;
    }
  }
}
