.progress-bar {
  z-index: 998;
  position: fixed;
  left: 2rem;
  top: 50%;
  width: 0.35rem;
  height: 12rem;
  transform-origin: top;
  transform: translate(0, -50%);
  border: 1px solid $white;
  mix-blend-mode: difference;

  @include respond(short-with-sugar) {
    left: 1rem;
    top: 15%;
  }
  &--progress {
    width: 100%;
    height: 100%;
    transform-origin: top;
    background-color: $pink;
    opacity: 0.75;
  }

  &--caption {
    position: fixed;
    top: 15%;
    left: -1.5rem;
    width: 5rem;
    transform: rotate(-90deg);
    opacity: 0.85;
    font-size: 0.78rem;
    font-family: $font;
    text-align: center;
  }
}
