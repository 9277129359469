.image {
  height: 100%;
  border-radius: inherit;

  &--container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding: 2rem 0;
    border-radius: inherit;

    @include respond(tall) {
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  &--title {
    position: absolute;
    bottom: -0.5rem;
    width: 100%;
    text-align: center;
    font-family: $font;
    font-weight: 100;
    font-size: 1.2rem;
  }

  &--caption {
    transform: rotate(-180deg);
    opacity: 0.85;
    padding: 0.5rem;
    font-family: $font;
    font-size: 0.85rem;
    text-align: end;
    writing-mode: tb-rl;

    @include respond(tall) {
      transform: rotate(0);
      text-align: initial;
      writing-mode: initial;
      align-self: flex-end;
    }
  }
}
