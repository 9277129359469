.logo {
  z-index: 2000;
  position: fixed;
  top: 1rem;
  left: 2rem;
  padding: 0.5rem;
  mix-blend-mode: difference;
  font-size: 4rem;
  font-weight: 100;
  cursor: pointer;
}
