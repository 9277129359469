.about {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @include respond(short-with-sugar) {
    padding: 3rem 0.75rem;
  }

  &__header {
    position: relative;
    // height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    // background: url(./beard.png);
    // background-repeat: no-repeat;
    // background-position: right;
    // background-size: 45%;

    @include respond(trenta) {
      min-height: 51rem;
    }

    &__drawing {
      position: absolute;
      right: 0;
      bottom: -5rem;
    }

    // @include respond(venti-with-sugar) {
    //   background-size: 60%;
    // }

    // @include respond(venti) {
    // background-size: 75%;
    //   background-position: 50% 85%;
    // }

    // @include respond(short-with-sugar) {
    //   background-size: 120%;
    // }

    &--title {
      margin-left: 5rem;
      font-weight: 100;
      text-align: left;
      font-size: 4rem;
      text-transform: uppercase;
      line-height: 10rem;
      @include respond(venti-with-sugar) {
        font-size: 3rem;
        line-height: 7rem;
      }

      @include respond(short-with-sugar) {
        margin-top: 3rem;
        margin-left: initial;
        font-size: 2.5rem;
        text-align: center;
        line-height: initial;
      }

      // & > span {
      //   &:first-child {
      //     margin-left: 1rem;
      //   }
      //   &:nth-child(8) {
      //     margin-left: 2rem;
      //   }
      //   &:nth-child(14) {
      //     margin-left: 3rem;
      //   }
      //   &:nth-child(22) {
      //     margin-left: 4rem;
      //   }
      //   @include respond(venti) {
      //     &:first-child,
      //     &:nth-child(8),
      //     &:nth-child(14),
      //     &:nth-child(22) {
      //       margin-left: initial;
      //     }
      //   }
      // }
    }
  }

  &__introduction {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;

    @include respond(venti) {
      flex-flow: column nowrap;
      justify-content: center;
    }

    &--image {
      z-index: 1;
      width: 50%;
      min-width: 35rem;
      height: 100%;
      padding: initial;
      box-shadow: 0.75rem 0.75rem 0 $white;

      @include respond(venti) {
        width: 85%;
        min-width: 25rem;
      }

      & > img {
        width: 100%;
        height: initial;
      }
    }

    &--triangle {
      position: absolute;
      z-index: 0;
      top: 0rem;
      left: -5rem;
      height: 25rem;
      width: 25rem;
      transform-origin: top;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: $white;
      @include respond(venti) {
        height: 20rem;
        width: 20rem;
      }
    }

    &__text {
      position: relative;
      z-index: 10;
      width: 45%;
      display: flex;
      flex-flow: column nowrap;
      margin-left: 2rem;

      @include respond(venti) {
        width: 85%;
        margin-top: 2rem;
      }

      @include respond(short-with-sugar) {
        margin-left: initial;
        width: 95%;
      }
      & > svg {
        width: 800px;
        position: absolute;
        top: -8rem;
        right: 0;

        @include respond(short-with-sugar) {
          width: 110%;
          top: -10rem;
        }
      }

      & > h1 {
        font-size: 3rem;
      }

      & > p {
        margin-bottom: 2rem;
        font-family: $font;

        @include respond(short-with-sugar) {
          margin-top: 1rem;
          font-size: 1.1rem;
        }
      }
    }
  }

  &__academics {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 3rem 0;

    @include respond(venti) {
      margin-bottom: 5rem;
    }

    @include respond(short) {
      flex-flow: column nowrap;
    }

    &--title {
      width: 100%;
      text-align: right;
      font-size: 5rem;
    }

    &__list {
      z-index: 100;
      width: 45%;
      max-width: 50rem;
      list-style: none;
      font-family: $font;

      @include respond(venti-with-sugar) {
        margin-top: 4rem;
      }
      @include respond(venti) {
        width: 75%;
      }

      @include respond(short) {
        width: 100%;
      }

      & > li {
        display: flex;
        flex-flow: column nowrap;
        margin: 1rem 0;
        padding: 1rem;
        font-size: 2rem;
        border-bottom: 1px solid;
        border-radius: 0.125rem;

        & > span {
          width: 100%;
          margin-left: 1.5rem;
          font-size: 1rem;
          color: $grey;
        }
      }
    }
    &--logo-container {
      position: absolute;
      bottom: -11rem;
      left: -15rem;
      width: 30rem;
      height: 30rem;
      justify-content: center;
      opacity: 0.5;
      border-radius: 100%;
      @include respond(venti) {
        width: 25rem;
        height: 25rem;
        left: -12.5rem;
        opacity: 0.3;
      }
    }
    &--year {
      margin-left: 2rem;
      text-shadow: 0 0 4rem $white;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-align: center;
      font-weight: 100;
      font-size: 2.5rem;
      color: $grey;
      opacity: 0.5;

      @include respond(short) {
        margin-left: initial;
        margin-right: 0.25rem;
        writing-mode: initial;
        text-orientation: initial;
        text-align: right;
        opacity: 0.8;
        color: $white;
        font-weight: bold;
      }
    }
  }

  &__work {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin-top: 5rem;
    padding: 2rem;

    @include respond(venti) {
      padding: 1rem;
    }

    &--title {
      width: 100%;
      text-align: left;
      font-size: 5rem;
    }

    &__boxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      @include respond(venti) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      &__box {
        margin: 1rem;
        padding: 1rem;
        @include respond(short-with-sugar) {
          margin: 1rem 0;
        }

        &:first-child {
          border-left: 1px solid $white;
          border-bottom: 1px solid $white;
        }
        &:nth-child(2) {
          border-top: 1px solid $white;
          border-left: 1px solid $white;

          @include respond(venti) {
            border-top: initial;
            border-bottom: 1px solid $white;
          }
        }
        &:nth-child(3) {
          border-right: 1px solid $white;
        }
        &:nth-child(4) {
          border-top: 1px solid $white;
          border-right: 1px solid $white;
        }
        & h1 {
          text-transform: uppercase;
        }
        & > span {
          margin-left: 1rem;
          font-size: 1.17rem;
          font-weight: bold;
        }
        p {
          font-family: $font;
          margin-left: 1rem;
        }
      }
    }
  }
}
