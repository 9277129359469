.home {
  overflow-x: hidden;
  overflow-y: hidden;
  &__header {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;

    @include respond(short-with-sugar) {
      min-height: 90vh;
      align-items: center;
    }

    &--arrow {
      position: absolute;
      left: 30%;
      top: 20%;
      width: 7rem;
      height: 7rem;
      // transform: rotate(90deg);
      // stroke-width: 1px;
    }

    &--title {
      position: absolute;
      z-index: 10;
      left: 1rem;
      bottom: 10%;
      width: 95%;
      padding-left: 3rem;
      font-size: 4rem;
      font-weight: 100;
      text-shadow: 0.1rem 0.1rem $black;

      @include respond(venti) {
        font-size: 3.5rem;
      }

      @include respond(grande) {
        font-size: 3rem;
      }

      @include respond(short-with-sugar) {
        font-size: 2.5rem;
        padding-left: 1rem;
      }
    }

    &--image-container {
      position: relative;
      width: auto;
      height: 45rem;
      transform-origin: bottom;
      justify-self: flex-end;
      margin-right: 3rem;
      margin-left: 1rem;

      @include respond(tall) {
        height: 47rem;
        margin-right: 0;
      }
      @include respond(short-with-sugar) {
        width: 100%;
        height: initial;
        margin-left: 0;
      }
      @include respond(depth-charge) {
        height: 50rem;
      }

      @include respond(depth-charge-with-sugar) {
        height: 64rem;
      }
    }
    &--image {
      height: 100%;
      @include respond(short-with-sugar) {
        width: 90vw;
      }
    }
  }

  &__designs {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    margin-top: 9rem;

    &--title {
      width: 100%;
      font-size: 7.5rem;
      padding: 0 6rem;
      text-align: left;

      @include respond(short-with-sugar) {
        padding-left: 2.5rem;
        font-size: 6rem;
      }
    }

    &--shape {
      z-index: -1;
      position: absolute;
      right: 0rem;
      top: -10rem;
      transform: rotate(140deg);
      opacity: 0.2;
    }

    &__container {
      z-index: 1;
      flex-grow: 1;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      padding: 1rem 2rem;

      @include respond(short-with-sugar) {
        flex-flow: column nowrap;
        margin-top: -4rem;
      }

      & :first-child {
        margin-top: 4rem;
        @include respond(short-with-sugar) {
          margin-top: 0rem;
        }
      }

      & :nth-child(3) {
        margin-top: 7rem;

        @include respond(short-with-sugar) {
          margin-top: 0rem;
        }
      }

      & :nth-child(4) {
        margin-left: 5rem;
        @include respond(trenta) {
          margin-left: 0rem;
        }
      }

      & :last-child {
        margin-top: 10rem;

        @include respond(trenta) {
          margin-top: 1rem;
        }
        @include respond(short-with-sugar) {
          margin-top: 0rem;
        }
      }
    }

    &--counter {
      position: absolute;
      left: 15%;
      top: 30%;
      font-size: 2.4rem;
    }

    &--image-container {
      width: auto;
      height: 25rem;
      margin: 0 2rem;
      opacity: 0.7;

      @include respond(trenta) {
        margin: 0 1rem;
      }

      @include respond(depth-charge) {
        height: 30rem;
      }
    }

    &__triangle {
      position: absolute;
      z-index: 0;
      left: 50%;
      height: 30rem;
      width: 30rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-end;
      transform-origin: center;
      opacity: 0.1;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: $white;
      @include respond(depth-charge) {
        height: 35rem;
        width: 35rem;
      }
      &--title {
        width: 45%;
      }
    }
  }

  &__researches {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 100vh;

    &--title {
      z-index: 99;
      width: 100%;
      font-size: 7.5rem;
      // padding: 0 6rem;
      text-align: right;

      @include respond(short-with-sugar) {
        // padding-left: 2.5rem;
        font-size: 6rem;
      }

      @include respond(short) {
        // padding-left: 1rem;
        font-size: 4.5rem;
      }
    }

    &--container {
      width: 100%;
      display: flex;
      height: 45rem;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      padding-right: 0;

      @include respond(venti) {
        height: 65rem;
        flex-flow: column nowrap;
      }
    }

    &--shape {
      position: absolute;
      z-index: 101;
      left: 40%;
      top: 40%;
      width: 15rem;
      height: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4rem;
      font-weight: 100;
      font-family: $font;
      background-color: $black;
      color: $white;
      opacity: 0.7;
      transition: all 0.5s ease;
    }

    &__image-container {
      z-index: 100;
      position: relative;
      width: 40%;
      // width: auto;
      // flex-grow: 1;
      justify-content: center;

      & > img {
        width: 100%;
        height: initial;
      }
      @include respond(venti) {
        margin-top: 9rem;
        padding-bottom: 0;
      }

      @include respond(tall) {
        width: 85%;
        height: auto;
        & > img {
          width: 100%;
          height: initial;
        }
      }
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;
      z-index: 10;
      list-style: none;
      align-self: flex-start;

      @include respond(venti) {
        transform: initial;
      }

      @include respond(short) {
        margin-top: -9rem;
      }

      & > a {
        margin: 1.5rem 0;
        padding-right: 1rem;

        & > span {
          color: $white;
          mix-blend-mode: difference;
          &:visited {
            color: $white;
            mix-blend-mode: difference;
          }
        }

        @include respond(short-with-sugar) {
          padding-left: 0.75rem;
          padding-right: 0;
        }
      }
    }

    &__rect {
      position: absolute;
      z-index: -1;
      right: -20%;
      top: 5%;
      transform: rotate(180deg);
      opacity: 0.5;
      mix-blend-mode: difference;

      &--second {
        right: initial;
        top: initial;
        left: -20%;
        bottom: 5%;
      }
    }
  }
}
