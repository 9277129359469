*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.app {
  position: relative;
  height: 100%;

  &:after {
    content: "";
    position: fixed;
    z-index: -2;
    top: -100%;
    left: -50%;
    height: 300%;
    width: 300%;
    background-image: url("/tactile_noise.png");
    opacity: 1;
    animation: grain 8s steps(10) infinite;
  }
}

a {
  text-decoration: none;
  color: $white;
  // mix-blend-mode: difference;

  // &:visited {
  //   color: $black;
  //   mix-blend-mode: difference;
  // }
}

.word-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

::selection {
  background-color: $white;
}

body {
  box-sizing: border-box;
}

html {
  font-family: "Arima Madurai", Arial, Helvetica, sans-serif;
  background-color: $black;
  color: $white;
  font-size: 100%; //this defines what 1 rem is

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include respond(depth-charge) {
    font-size: 125%;
  }
  @include respond(depth-charge-with-sugar) {
    font-size: 130%;
  }
  @include respond(trenta) {
    font-size: 90%;
  }
  @include respond(venti-with-sugar) {
    font-size: 85%;
  }
  @include respond(venti) {
    font-size: 80%;
  }
  @include respond(grande) {
    font-size: 75%;
  }
  @include respond(short-with-sugar) {
    font-size: 70%;
  }
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}
