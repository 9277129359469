.cursor {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 2.7rem;
  height: 2.7rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border: 1px solid $pink;
  // background: $white;
  border-radius: 50%;
  mix-blend-mode: difference;
}
