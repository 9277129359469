//Sizes are chosen based on coffee cups' size!
// short > tall > grande > venti > trenta > depth-charge
// in addition I use -with-sugar > -with-room for extra queries!

@mixin respond($breakpoint) {
  @if $breakpoint == short {
    @media only screen and (max-width: 26.5625em) {
      @content;
    }
  }
  @if $breakpoint == short-with-sugar {
    @media only screen and (max-width: 32em) {
      @content;
    }
  }
  @if $breakpoint == tall {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == grande {
    @media only screen and (max-width: 48em) {
      @content;
    }
  }

  @if $breakpoint == venti {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == venti-with-sugar {
    @media only screen and (max-width: 64em) {
      @content;
    }
  }

  @if $breakpoint == trenta {
    @media only screen and(max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == depth-charge {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }

  @if $breakpoint == depth-charge-with-sugar {
    @media only screen and (min-width: 160em) {
      @content;
    }
  }
}
