.contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding-top: 2rem;
  font-style: normal;

  &__title {
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 0;
    font-size: 5.5rem;
    font-weight: 100;
    text-align: left;

    @include respond(trenta) {
      font-size: 5rem;
    }

    @include respond(venti-with-sugar) {
      font-size: 4rem;
      text-align: center;
    }

    @include respond(short-with-sugar) {
      font-size: 3rem;
    }

    &--underlined {
      border-bottom: 2px solid $white;

      @include respond(short-with-sugar) {
        border-bottom: initial;
      }
    }
    &--second-line {
      padding-top: 0;
      text-align: right;

      @include respond(venti) {
        text-align: left;
      }

      @include respond(venti-with-sugar) {
        text-align: center;
      }
      @include respond(short-with-sugar) {
        font-weight: bold;

        color: $pink;
      }
    }
  }
  &--button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 1rem 0;
    margin-top: 2rem;

    @include respond(short-with-sugar) {
      margin-top: initial;
    }
  }

  &--button {
    font-size: 1.8rem;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5rem;
    background-color: $white;
    opacity: 0.8;

    &__shape {
      z-index: 10;
      width: 10rem;
      height: 10rem;
      background-color: $white;
      cursor: grab;

      @include respond(grande) {
        width: 7rem;
        height: 7rem;
      }

      @include respond(short-with-sugar) {
        width: 5rem;
        height: 5rem;
      }
      &--circle {
        border-radius: 50%;
      }
      &--triangle {
        cursor: initial;
      }
    }
  }
}
